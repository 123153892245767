<template>
    <div class="content skillTest">
        <div class="skillTest-tit"><span @click="$router.push('/')">首页</span>><span @click="$router.back(-1)">{{carName}}科目</span>><font color="#ff470f">{{ $route.query.name }}</font>
        </div>
        <div class="skillTest-con">
            <ul
                class="skillTest-topic-con flex"
                v-if="list.length > 0"
            >
                <template v-for="(item,index) in list">
                    <li
                        :key="item.Id"
                        v-if="item.IsContProblem"
                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:$route.query.type}})"
                    ><span>
                            <samp>{{ index + 1 }}</samp>
                        </span><samp>{{item.Name}}</samp></li>
                    <li
                        :key="item.Id"
                        v-else
                        @click="$router.push({path:`/skillTest/${item.Id}`,query:{name:item.Name,type:$route.query.type}})"
                    ><span>
                            <samp>{{ index + 1 }}</samp>
                        </span><samp>{{item.Name}}</samp></li>
                </template>
            </ul>
            <template v-else>
                <no-content></no-content>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../styles/public.scss';
@import 'skillTest.scss';
.skillTest-topic-con {
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            list: [],
            id: 0
        };
    },
    computed: {
        ...mapState(['car']),
        carName() {
            if (this.car && this.car.car) {
                const item = this.car.car.find(a => a.Id === this.car.selectId);
                if (item) {
                    return item.Name;
                }
                return '';
            }
            return '';
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.getCategory();
    },
    watch: {
        $route(newValue) {
            if (this.id !== newValue.params.id) {
                this.id = newValue.params.id;
                this.getCategory();
            }
        }
    },
    methods: {
        getCategory() {
            if (this.id > 0) {
                this.$api.getSubCategory({ categoryId: this.id }).then(res => {
                    if (res.flag) {
                        this.list = res.data;
                    }
                });
            }
        }
    }
};
</script>
